<template>
  <div class="terms-and-conditions" v-if="show" @click.self="$emit('close')">
    <div class="inner">
      <span class="icon-times" @click="$emit('close')"></span>
      <h1>Terms of Use</h1>
      <p>
        <strong>General Terms and Conditions.</strong><br>All Rentals are subject to the Terms and Condition printed on the Rental Agreement.
      </p>
      <p><strong>DRIVING RULES</strong></p>
      <ul>
        <li>In Cyprus the traffic moves on the LEFT side of the road. NOT the right.</li>
        <li>For your own safety and the safety of the others, you must adhere strictly to the national speed limits.
          Maximum speed limit on highways is 100 km/h and the minimum is 65 km/h. Unless otherwise stated on sigh posts,
          the maximum speed limit on ordinary inter-urban and rural roads is 80 km/h. Inbuilt up areas, the maximum
          limit is 50km/h although local limits are often set lower. International road traffic signs are in use and are
          placed along the roads and high-ways, on the left hand-side.
        </li>
        <li>It is prohibited to drive a motor vehicle when under the influence of alcohol. The admissible limit in the
          blood is 50 milligrams of alcohol in 100 milliliters of blood. The admissible limit in one’s breath is up to
          22 micrograms of alcohol in 100 milliliters of exhaled air.
        </li>
        <li>The use of hand-held mobile phones while driving is prohibited. Drivers wishing to use their mobile phone while driving must use a hands-free device.</li>
        <li>You can drive using a valid driving license, provided it is valid for the class of vehicle you wish to drive,
          European citizens may drive using the driving license issued by their country.
        </li>
        <li>Seat belts are obligatory both in the front and the seats. Children with a height of less than 1.5 m must
          wear a suitable safety belt or be restrained with a suitable restraining system.
        </li>
        <li>Parking and waiting on a double yellow line is prohibited. Waiting is only allowed on a single yellow
          line.
        </li>
        <li>In case of an accident, please call 199 or 112 immediately and inform the car rental company.</li>
      </ul>
      <p><strong>RATES:&nbsp;</strong>Car rates are in Euro (€) , VAT included in a price plus unlimited mileage.</p>
      <p><strong>CAR TYPE:&nbsp;</strong>Company reserves the right to substitute alternative car model to that originally reserved of an equivalent or higher model type.</p>
      <p><strong>AGE REQUIREMENT:&nbsp;</strong>Drivers must be over 21 years of age. Maximum age for drivers is 70 years old.</p>
      <p><strong>DRIVING LICENSE:&nbsp;</strong>A valid driving license held for at least three years is required from the driver.</p>
      <p><strong>INSURANCE COVERAGE:&nbsp;</strong>A.S. Alpan Car Rentals ltd cover you with third party insurance at all times at no extra cost.
        To reduce liability for damage you ca pay for extra insurance i.e. Collision Damage Waiver, Additional Damage Waiver, Super Damage Waiver,
        Personal Accident Insurance, Theft Protection.</p>
      <p>Non of the insurance coverage will cover damages appeared as the result of:</p>
      <p>Driving the vehicle under the influence of drugs and/or alcohol,
        in rough terrain conditions and off-roads (non asphalted areas) or accidents at the territory of North Cyprus (occupied areas)</p>
      <p>All the insurance coverage will be immediately cancelled if the renter
        leaves the accident place before appearance of the official representative of insurance company, police or rental car representative.</p>
      <p><strong>CROSSING TO THE OCCUPIED AREAS:&nbsp;</strong>You need rental car representative permission to visit territory of North Cyprus ( occupied
        Additional insurance cover is required. Contact us for further information.</p>
      <p><strong>COLLECTION / DELIVERY:&nbsp;</strong>Usually collection and delivery is free within town limits and working hours. Airport collection or one way rental ( returning the car to a different rental location) may involve an additional cost.
        Returning the vehicle outside business hours is not advisable. If you fail to return it on time you will also be charged for the delay.</p>
      <p><strong>FUEL:&nbsp;</strong>Fuel is excluded from the rental price and not refundable.
        Fuel policy is ”Full-Full”. Upon return and only then, of the rented vehicle to company if remaining amount of fuel in the tank,
        which is less than that of initial quantity, a Refuelling Service Charges will be applied.</p>
      <p><strong>ADDITIONAL DRIVER:&nbsp;</strong>f you require an additional driver there is an extra charge of €5.00 per day has to be paid.</p>
      <p><strong>BABY SEATS:&nbsp;</strong>Baby seats are available upon request at an additional charge.</p>
      <p><strong>TRAFFIC FINES:&nbsp;</strong>Traffic fines that result from violating Cyprus Traffic Law are the renter’s liability
        and they might also involve an administration fee from the rental company.</p>
      <p><strong>KEY:&nbsp;</strong>Loss or damage of vehicle key is not covered by any type of insurance or coverage package.
        Customer undertakes fully compensate the price of vehicle key in such a case.</p>
      <p><strong>CANCELLATION POLICY:&nbsp;</strong>Cancellations upon 2 days before rental pickup are free of charge, for all others a cancellation fee may apply.</p>
      <p><strong>METHODS OF PAYMENT:&nbsp;</strong>Cash, Debit/Credit Cards, PayPal. Bank Transfer.</p>
      <p><strong>DEPOSIT FOR GUARANTEE:&nbsp;</strong>Upon pick up of the vehicle, accept cash deposit or we pre-authorise an amount from the driver’s credit card as a deposit for guarantee which will be fully refunded at the end of the rental, provided no other charges arise.
        The amount of the deposit varies according to the car group.</p>
      <button @click="agreedWithTerms">I agree with the terms & conditions</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsAndConditions",
  props: {
    show: {type: Boolean, required: true}
  },
  methods: {
    agreedWithTerms() {
      this.$emit('agreed')
      this.$nextTick().then(() => {
        this.$emit('close')
      })
    }
  }
}
</script>

<style scoped>
.terms-and-conditions {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0,0,0,0.3);
}
.terms-and-conditions .inner {
  width: calc(60% - 20px);
  margin: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  max-height: calc(90vh - 20px);
  overflow-y: auto;
  padding: 10px;
  position: relative;
}
.terms-and-conditions .icon-times {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 2rem;
}
.terms-and-conditions h1 {
  text-align: center;
  font-weight: 400;
}
.terms-and-conditions strong {
  font-weight: 500;
  font-size: 1rem;
  display: inline-block;
  margin-bottom: 10px;
}
.terms-and-conditions p {
  text-align: left;
  font-size: .9rem;
}
.terms-and-conditions ul {
  /*padding-left: 10px;*/
  margin-top: 0;
  text-align: left;
}
.terms-and-conditions li {
  margin-top: 10px;
  font-size: .9rem;
}
.terms-and-conditions button {
  width: 300px;
  padding: 10px 5px;
  color: #ffffff;
  background-color: #ff6600;
  border-radius: 5px;
  font-size: 1.1rem;
  margin-top: 40px;
}
@media screen and (max-width: 991px) {
  .terms-and-conditions .inner {
    width: calc(100% - 20px);
  }
}
</style>