<template>
  <div class="info-page">
    <div class="car-name">{{car.name}}</div>
    <div class="body">
      <div class="img-div">
        <img :src="getImageSrc(car.image, 'cars')" alt="">
      </div>
      <div class="info-div">
        <div class="title">
          <span>Price from</span>
          <div class="bordered-top"><span>{{price}}</span> EUR / day</div>
        </div>
        <div class="btn-div">
          <div>
            <div class="fake-btn">Mileage:</div>
            <span>Unlimited</span>
          </div>
          <div>
            <div class="fake-btn second">Deposit:</div>
            <span>{{deposit}} EUR</span>
          </div>
        </div>
        <div class="title bordered-bottom">Car Information</div>
        <div class="params">
          <div>
            <div class="spans">
              <span class="icon-car"></span><span>Class: {{car.class}}</span>
            </div>
            <div class="spans">
              <span class="icon-car"></span><span>Doors: {{car.doors}}</span>
            </div>
            <div class="spans">
              <span class="icon-user"></span><span>Minimal driver age: {{car.minimal_driver_age}}</span>
            </div>
          </div>
          <div>
            <div class="spans">
              <span class="icon-cogs"></span><span>Gearbox: {{gearbox[car.transmission]}}</span>
            </div>
            <div class="spans">
              <span class="icon-users"></span><span>Max passengers: {{car.passengers}}</span>
            </div>
            <div class="spans">
              <span class="icon-briefcase"></span><span>Max luggage: {{car.max_luggage}}</span>
            </div>
          </div>
          <div>
            <div class="spans">
              <span class="icon-tachometer"></span><span>Fuel: {{fuel[car.fuel]}}</span>
            </div>
            <div class="spans">
              <span class="icon-tachometer"></span><span>Engine capacity: {{car.engine_volume}}</span>
            </div>
          </div>
        </div>
        <div class="title bordered-bottom">Additional information</div>
        <div class="params">
          <div v-for="(row, i) in rowsCount(car.options)" :key="row">
            <div class="spans" v-if="car.options[i * 3]"><span class="icon-check"></span><span>{{car.options[i * 3]}}</span></div>
            <div class="spans" v-if="car.options[(i * 3) + 1]"><span class="icon-check"></span><span>{{car.options[(i * 3) + 1]}}</span></div>
            <div class="spans" v-if="car.options[(i * 3) + 2]"><span class="icon-check"></span><span>{{car.options[(i * 3) + 2]}}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="rent-title">{{requestStep === 0 ? 'Rent ' + car.name : requestStep === 1 ? 'Reservation details' : 'Rental details'}}</div>
    <transition name="request" mode="out-in">
      <div class="pickup-div" v-if="requestStep === 0" :key="'0'">
        <div class="col">
          <span>Pick-Up {{isMobile ? 'Information' : 'Location'}}</span>
          <select v-model="reservationDetails.pickup_location">
            <option value="">Pick-Up City & Location</option>
            <option value="Paphos Office">Paphos Office</option>
            <option value="Paphos Airport">Paphos Airport</option>
            <option value="Larnaca Airport">Larnaca Airport</option>
          </select>
        </div>
        <div class="col">
          <span v-if="!isMobile">Pick-Up Date</span>
          <div class="datetime">
            <input type="date" v-model="reservationDetails.pickup_date" :min="dateNow">
            <input type="time" v-model="reservationDetails.pickup_time">
          </div>
        </div>
        <div class="col pickup-margin">
          <span>Return {{isMobile ? 'Information' : 'Location'}}</span>
          <select v-model="reservationDetails.return_location">
            <option value="">Return City & Location</option>
            <option value="Paphos Office">Paphos Office</option>
            <option value="Paphos Airport">Paphos Airport</option>
            <option value="Larnaca Airport">Larnaca Airport</option>
          </select>
        </div>
        <div class="col">
          <span v-if="!isMobile">Return Date</span>
          <div class="datetime">
            <input type="date" v-model="reservationDetails.return_date" :min="dateTomorrow">
            <input type="time" v-model="reservationDetails.return_time">
          </div>
        </div>
        <button @click="changeRequestStep(1)" class="pickup-margin">Continue</button>
      </div>
      <div v-else-if="requestStep === 1" class="reservation-details" :key="'1'">
        <div class="res-info">
          <div>
            <div>Period</div>
            <div class="res-data"><span class="icon-calendar-alt"></span><span>{{reservationDetails.pickup_date + ' ' + reservationDetails.pickup_time}}</span></div>
            <div class="res-data center"><span class="icon-calendar-alt"></span><span>{{reservationDetails.return_date + ' ' + reservationDetails.return_time}}</span></div>
            <div class="res-data"><span class="icon-clock"></span><span>Duration: {{rentDuration}}</span></div>
          </div>
          <div>
            <div>Pick-up</div>
            <div class="res-data"><span class="icon-map-marker"></span><span>{{reservationDetails.pickup_location}}</span></div>
            <div class="res-data center"><span class="icon-map-signs"></span><span>{{reservationDetails.pickup_location}}</span></div>
            <div class="res-data"><span class="icon-money"></span><span>Fee: &#x20ac; ???</span></div>
          </div>
          <div>
            <div>Return</div>
            <div class="res-data"><span class="icon-map-marker"></span><span>{{reservationDetails.return_location}}</span></div>
            <div class="res-data center"><span class="icon-map-signs"></span>{{reservationDetails.return_location}}<span></span></div>
            <div class="res-data"><span class="icon-money"></span><span>Fee: &#x20ac; ???</span></div>
          </div>
        </div>
        <div class="buttons">
          <button @click="changeRequestStep(0)">Change Date, Time & Location</button>
          <button>Change cars</button>
        </div>
        <div class="rent-title child">Selected cars</div>
        <div class="rent-table">
          <div class="head">
            <div>Cars</div>
            <div>Price</div>
            <div>Deposit</div>
            <div class="last">Quantity</div>
          </div>
          <div>
            <div>{{car.name}}</div>
            <div>{{price}}</div>
            <div>{{deposit}} EUR</div>
            <div class="last">1</div>
          </div>
        </div>
        <div class="rent-title child">Rental options</div>
        <div class="rent-table">
          <div class="head">
            <div>Extra</div>
            <div>Total</div>
            <div>Deposit</div>
            <div class="last">Quantity</div>
          </div>
          <div>
            <div>No car extras available. Click on continue button </div>
            <div></div>
            <div></div>
            <div class="last"></div>
          </div>
        </div>
        <div class="buttons">
          <button style="margin-right: 0;" @click="changeRequestStep(2)">Continue</button>
        </div>
      </div>
      <div v-else class="rental-details">
        <div class="rent-table no-grid shadowed">
          <div class="head no-border">
            <div>Pick-up Location</div>
            <div>Return Location</div>
            <div></div>
          </div>
          <div class="no-border">
            <div>{{ reservationDetails.pickup_location }}</div>
            <div>{{reservationDetails.return_location}}</div>
            <div></div>
          </div>
          <div class="head no-border">
            <div>Pick-up Date & Time</div>
            <div>Return Date & Time</div>
            <div class="last">Period</div>
          </div>
          <div class="no-border">
            <div>{{ reservationDetails.pickup_date + ' ' + reservationDetails.pickup_time }}</div>
            <div>{{reservationDetails.return_date + ' ' + reservationDetails.return_time}}</div>
            <div class="last">{{rentDuration}}</div>
          </div>
          <div class="head no-border">
            <div>Selected cars</div>
            <div>Price</div>
            <div class="last">Total</div>
          </div>
          <div class="no-border">
            <div>{{ car.name }}</div>
            <div>{{ price }} &#x20ac;</div>
            <div class="last">{{reservationDetails.sub_total}} &#x20ac;</div>
          </div>
          <div class="head no-border">
            <div>Location Fees</div>
            <div></div>
            <div></div>
          </div>
          <div class="no-border">
            <div>Pick-up fee</div>
            <div>---</div>
            <div class="last">---</div>
          </div>
          <div class="no-border center">
            <div>Return fee</div>
            <div>---</div>
            <div class="last">---</div>
          </div>
          <div class="head no-border">
            <div>Total</div>
            <div></div>
            <div></div>
          </div>
          <div class="no-border">
            <div>Sub Total:</div>
            <div></div>
            <div class="last">&#x20ac; {{reservationDetails.sub_total}}</div>
          </div>
          <div class="no-border center">
            <div>VAT(19.00%):</div>
            <div></div>
            <div class="last">&#x20ac; {{reservationDetails.vat}}</div>
          </div>
          <div class="no-border">
            <div>Grand Total:</div>
            <div></div>
            <div class="last">&#x20ac; {{reservationDetails.grand_total}}</div>
          </div>
          <div class="no-border center">
            <div>Deposit:</div>
            <div></div>
            <div class="last">&#x20ac; {{deposit}}</div>
          </div>
        </div>
        <div class="buttons">
          <button @click="changeRequestStep(0)">Change Date, Time & Location</button>
          <button>Change Cars</button>
          <button @click="changeRequestStep(1)">Change Rental Options</button>
        </div>
        <div class="rent-table no-grid">
          <div class="head">
            <div>Search for existing customer details</div>
          </div>
          <div class="no-border inputs">
            <input type="text" placeholder="Email">
            <select name="">
              <option :value="0" :key="0">Year Of Birth</option>
              <option :value="year" v-for="year in getYearsArray()" :key="year">{{year}}</option>
            </select>
            <button>Fetch Details</button>
          </div>
        </div>
        <div class="rent-table no-grid">
          <div class="head">
            <div>Or Create A New Account</div>
          </div>
          <div class="no-border for-i">
            <div class="spans for-i">
              <label for="title">Title:<b>*</b></label>
              <select id="title" v-model="customerRequest.title">
                <option value=""></option>
                <option value="Mr. ">Mr.</option>
                <option value="Ms. ">Ms.</option>
                <option value="Mrs. ">Mrs.</option>
                <option value="Miss. ">Miss.</option>
                <option value="Dr. ">Dr.</option>
                <option value="Prof. ">Prof.</option>
              </select>
            </div>
            <div class="spans for-i"><label for="fname">First Name:<b>*</b></label><input type="text" id="fname" v-model="customerRequest.firstname"></div>
          </div>
          <div class="no-border for-i">
            <div class="spans for-i"><label for="lname">Last Name:<b>*</b></label><input type="text" id="lname" v-model="customerRequest.lastname"></div>
            <div class="spans for-i">
              <label for="">Date Of Birth:<b>*</b></label>
              <div class="dates">
                <select name="" id="yyyy" v-model="customerRequest.birthYear">
                  <option :value="0" :key="0">Year</option>
                  <option :value="year" v-for="year in getYearsArray()" :key="year">{{year}}</option>
                </select>
                <select name="" id="mm" v-model="customerRequest.birthMonth">
                  <option :value="0" :key="0">Month</option>
                  <option :value="month.value" v-for="month in getMonthsArray()" :key="month.value">{{month.title}}</option>
                </select>
                <select name="" id="dd" v-model="customerRequest.birthDay">
                  <option :value="0" :key="0">Day</option>
                  <option :value="day" v-for="day in getDaysArray()" :key="day">{{day}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="no-border for-i">
            <div class="spans for-i">
              <label for="address">Address:<b>*</b></label>
              <input type="text" id="address" v-model="customerRequest.address">
            </div>
            <div class="spans for-i">
              <label for="city">City:<b>*</b></label>
              <input type="text" id="city" v-model="customerRequest.city">
            </div>
          </div>
          <div class="no-border for-i">
            <div class="spans for-i">
              <label for="zip">Zip Code:</label>
              <input type="text" id="zip" v-model="customerRequest.zipCode">
            </div>
            <div class="spans for-i">
              <label for="country">Country:</label>
              <input type="text" id="country" v-model="customerRequest.country">
            </div>
          </div>
          <div class="no-border for-i">
            <div class="spans for-i">
              <label for="phone">Phone:<b>*</b></label>
              <input type="text" id="phone" v-model="customerRequest.phone">
            </div>
            <div class="spans for-i">
              <label for="email">Email:<b>*</b></label>
              <input type="text" id="email" v-model="customerRequest.email">
            </div>
          </div>
          <div class="no-border for-i">
            <div class="spans for-i text">
              <label for="comment">Additional Comments:</label>
              <textarea name="" id="comment" v-model="reservationDetails.comments" spellcheck="false"></textarea>
            </div>
          </div>
        </div>
        <div class="rent-table no-grid no-border">
          <div class="head"><div>Pay By: *</div></div>
          <div class="no-border pay">
            <div class="pay-method">
              <div>
                <span class="icon-credit-card"></span>
                <input type="radio" name="payment-type" value="MyPOS" v-model="reservationDetails.pay_method">
              </div>
              <div>
                <span>MyPOS</span>
                <p>Pay now securely with your credit or debit card.</p>
              </div>
            </div>
            <div class="pay-method">
              <div>
                <span class="icon-bank"></span>
                <input type="radio" name="payment-type" value="Bank Transfer" v-model="reservationDetails.pay_method">
              </div>
              <div>
                <span>Bank Transfer</span>
                <p>Your Bank Details</p>
              </div>
            </div>
            <div class="pay-method">
              <div>
                <span class="icon-money"></span>
                <input type="radio" name="payment-type" value="Pay at Pick-up" v-model="reservationDetails.pay_method">
              </div>
              <div>
                <span>Pay at Pick-up</span>
                <p>Pay by cash on arrival.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="terms">
          <input type="checkbox" v-model="agreedWithTerms">
          <span @click="openTerms = true">I agree with the terms & conditions*</span>
        </div>
        <div class="buttons" style="justify-content: center;">
          <button @click="changeRequestStep(3)">Confirm</button>
        </div>
      </div>
    </transition>
    <TermsAndConditions :show="openTerms" @close="openTerms = false" @agreed="agreedWithTerms = true"></TermsAndConditions>
    <Notification :show="notification.show" :type="notification.type" :text="notification.text" @close="closeNotification"></Notification>
  </div>
</template>

<script>
import {isMobile} from 'mobile-device-detect'
import http from "../services/http";

import TermsAndConditions from "../components/TermsAndConditions";
import Notification from "../components/Notification";

export default {
  name: "CarInfo",
  components: {
    TermsAndConditions, Notification
  },
  data() {
    return {
      gearbox: {1: 'Automatic', 2: 'Manual'},
      fuel: {1: 'Hybrid', 2: 'Unleaded 95', 3: 'Diesel'},
      notification: {
        show: false,
        type: '',
        text: ''
      },
      siteId: 0,
      requestStep: 0,
      car: {},
      price: 0,
      deposit: 0,
      reservationDetails: {
        desired_car: 0,
        pickup_location: '',
        pickup_date: '',
        pickup_time: '',
        return_location: '',
        return_date: '',
        return_time: '',
        days: 0,
        sub_total: 0,
        vat: '',
        grand_total: '',
        full_name: '',
        email: '',
        phone: '',
        pay_method: 'MyPOS',
        comments: ''
      },
      customerRequest: {
        title: '',
        firstname: '',
        lastname: '',
        birthYear: 0,
        birthMonth: 0,
        birthDay: 0,
        address: '',
        city: '',
        zipCode: '',
        country: '',
        phone: '',
        email: ''
      },
      dateNow: '',
      dateTomorrow: '',
      isMobile: isMobile,
      openTerms: false,
      agreedWithTerms: false
    }
  },
  created() {
    // setTimeout(() => {
    //   this.notification.show = true
    // }, 3000)
    console.log(this.$route)
    this.$store.dispatch('isReady').then(() => {
      const id = this.$route.query.i
      if (id && id >= '') {
        const car = this.$store.getters.car(id)
        console.log(JSON.stringify(car))
        if (car.id) {
          this.car = car
          this.siteId = this.$store.getters.siteId
          this.price = this.car.fixed_price[this.siteId]
          this.deposit = this.car.deposit[this.siteId]
          // this.customerRequest.site_id = this.reservationDetails.site_id = this.siteId
          this.reservationDetails.desired_car = this.car.id
        }
      }
    })
    let date = new Date();
    this.dateNow = this.reservationDetails.pickup_date = date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' : '') +
        (date.getMonth() + 1) + '-' + (date.getDate() < 10 ? '0' : '') + date.getDate()
    date = new Date(date.setDate(date.getDate() + 1))
    this.dateTomorrow = this.reservationDetails.return_date = date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' : '') +
        (date.getMonth() + 1) + '-' + (date.getDate() < 10 ? '0' : '') + date.getDate()
    this.reservationDetails.pickup_time = this.reservationDetails.return_time = date.toTimeString().split(' ')[0].split(':').slice(0, 2).join(':');
  },
  computed: {
    rentDuration() {
      const dateFrom = Date.parse(`${this.reservationDetails.pickup_date}T${this.reservationDetails.pickup_time}`)
      console.log('DATE FROM ', dateFrom)
      const dateTo = Date.parse(`${this.reservationDetails.return_date}T${this.reservationDetails.return_time}`)
      console.log('DATE TO ', dateTo)
      const interval = Math.ceil(((dateTo - dateFrom) / 1000) / 86400)
      if (interval === 1) return interval + ' day'
      else return interval + ' days'
    },
    durationNumber() {
      const dateFrom = Date.parse(`${this.reservationDetails.pickup_date}T${this.reservationDetails.pickup_time}`)
      const dateTo = Date.parse(`${this.reservationDetails.return_date}T${this.reservationDetails.return_time}`)
      console.log('DATE FROM ', dateFrom)
      console.log('DATE TO ', dateTo)
      console.log('DIFF ', dateTo - dateFrom)
      if (dateTo <= dateFrom) return 0
      return Math.ceil(((dateTo - dateFrom) / 1000) / 86400)
    },
    percentage() {
      const days = this.durationNumber
      // console.log(((days * this.price) / 100) * 19, (((days * this.price) / 100) * 19).toFixed(2))
      return parseFloat((((days * this.price) / 100) * 19).toFixed(2))
    },
    fullName() {
      return this.customerRequest.title + this.customerRequest.firstname + ' ' + this.customerRequest.lastname
    }
  },
  methods: {
    openNotification(type, text) {
      this.notification.type = type
      this.notification.text = text
      this.notification.show = true
    },
    closeNotification() {
      this.notification.show = false
      setTimeout(() => {
        this.notification.type = this.notification.text = ''
      }, 100)
    },
    rowsCount(options) {
      if (!options) return 0
      return Math.ceil(options.length / 3)
    },
    getImageSrc(image, key) {
      return http.getImageSrc(image, key)
    },
    changeRequestStep(nextStep) {
      // console.log('NEXT STEP ', nextStep, this.requestStep)
      let data = undefined
      switch (this.requestStep) {
        case 0:
          if (this.reservationDetails.pickup_location !== '' && this.reservationDetails.return_location !== '') {
            this.reservationDetails.days = this.durationNumber
            console.log(this.reservationDetails.days)
            if (this.reservationDetails.days <= 0) {
              this.openNotification('warn', 'Invalid return time')
              return
            }
            this.reservationDetails.sub_total = this.durationNumber * this.price
            this.reservationDetails.vat = this.percentage + ''
            this.reservationDetails.grand_total = ((this.price * this.durationNumber) + this.percentage) + ''
            this.requestStep = nextStep
          } else {
            if (this.reservationDetails.pickup_location === '') {
              this.openNotification('warn', 'Enter pick-up location')
            } else {
              this.openNotification('warn', 'Enter return location')
            }
          }
          break
        case 1:
          console.log(JSON.stringify(this.reservationDetails))
          this.requestStep = nextStep
          break
        case 2:
          console.log(JSON.stringify(this.reservationDetails))
          console.log(JSON.stringify(this.customerRequest))
          // this.requestStep = nextStep
          // const data = this.getClientFormValue()
          console.log(JSON.stringify(this.getClientFormValue()))
          data = this.getClientFormValue()
          if (data.error) {
            this.openNotification(data.type != null ? data.type : 'warn', data.message)
            return
          }
          http.setOrderWithRegistration(data.order, data.client).then(r => {
            console.log(r)
            if (r.data.error) {
              this.openNotification('danger', 'Something went wrong! Please, try again later.')
              return
            }
            this.openNotification('success', 'Your request and registration has been saved. Yo will be contacted soon.')
          })
          break
        // case 3:
          // console.log(JSON.stringify(this.reservationDetails))
          // console.log(JSON.stringify(this.customerRequest))
      }
    },
    getClientFormValue() {
      const data = {}
      if (this.customerRequest.title === '') {
        data.error = true
        data.message = 'Select title'
        return data
      } else if (this.customerRequest.firstname === '') {
        data.error = true
        data.message = 'Enter your firstname'
        return data
      } else if (this.customerRequest.lastname === '') {
        data.error = true
        data.message = 'Enter your lastname'
        return data
      } else if (this.customerRequest.birthYear === 0 || this.customerRequest.birthMonth === 0 || this.customerRequest.birthDay === 0) {
        data.error = true
        data.message = 'Enter your birth date'
        return data
      } else if (this.customerRequest.address === '') {
        data.error = true
        data.message = 'Enter your address'
        return data
      } else if (this.customerRequest.city === '') {
        data.error = true
        data.message = 'Enter your city'
        return data
      } else if (this.customerRequest.phone === '') {
        data.error = true
        data.message = 'Enter your phone number'
        return data
      } else if (this.customerRequest.email === '') {
        data.error = true
        data.message = 'Enter your email'
        return data
      } else if (!this.agreedWithTerms) {
        data.error = true
        data.type = 'danger'
        data.message = 'Read and agree with terms and conditions'
        return data
      } else {
        if (this.car && this.car.id > 0) {
          this.reservationDetails.full_name = this.fullName
          this.reservationDetails.email = this.customerRequest.email
          this.reservationDetails.phone = this.customerRequest.phone
          return {
            client: {
              site_id: this.siteId,
              full_name: this.fullName,
              birth_date: this.customerRequest.birthYear + '-' + this.customerRequest.birthMonth + '-' + this.customerRequest.birthDay,
              address: this.customerRequest.address,
              city: this.customerRequest.city,
              zip_code: this.customerRequest.zipCode,
              country: this.customerRequest.country,
              phone: this.customerRequest.phone,
              email: this.customerRequest.email
            },
            order: {
              site_id: this.siteId,
              client_id: this.customerRequest.id,
              desired_car: this.car.id,
              days: this.reservationDetails.days,
              car_price: this.car.fixed_price[this.siteId],
              car_deposit: this.car.deposit[this.siteId],
              sub_total: this.reservationDetails.sub_total,
              vat: this.reservationDetails.vat,
              grand_total: this.reservationDetails.grand_total,
              pay_method: this.reservationDetails.pay_method,
              pickup_info: {
                location: this.reservationDetails.pickup_location,
                date: this.reservationDetails.pickup_date,
                time: this.reservationDetails.pickup_time
              },
              return_info: {
                location: this.reservationDetails.return_location,
                date: this.reservationDetails.return_date,
                time: this.reservationDetails.return_time
              },
              contact_details: {
                full_name: this.fullName,
                phone: this.customerRequest.phone,
                email: this.customerRequest.email,
                comments: this.reservationDetails.comments
              }
            }
          }
        } else {
          data.error = true
          data.type = 'danger'
          data.message = 'Refresh page to get valid params'
          return data
        }
      }
    },
    getYearsArray() {
      const array = []
      const year = new Date().getFullYear() - 10;
      for (let i = 1945; i <= year; i++) {
        array.push(i)
        if (i === year) return array
      }
    },
    getMonthsArray() {
      const months = ['January','February','March','April','May','June','July','August','September','October','November','December']
      const arr = []
      for (let i = 1; i <= months.length; i++) {
        arr.push({value: i < 10 ? '0' + i : '' + i, title: months[i - 1]})
        if (i === months.length) return arr
      }
    },
    getDaysArray() {
      const arr = []
      for (let i = 1; i <= 31; i++) {
        arr.push(i)
        if (i === 31) return arr
      }
    }
  }
}
</script>

<style scoped src="../styles/car_info.css"></style>